import React from 'react';
import './Login.css';
import { OpenIDClient } from '../../services/openIDClient';
import logo from './../../assets/logo-horizontal.png';
import hero from './../../assets/login-background.svg';
import { LanguageSwitch } from '../../components/LanguageSwitch/LanguageSwitch';
import { useTranslation } from 'react-i18next';

export function Login() {
  const { t } = useTranslation();

  const signInWithVIDchain = async () => {
    const client = OpenIDClient.getInstance().getLoginClient();
    client.wipeTokens();
    await client.callback();
    const token = await client.getToken({
      scopes: {
        request: ['openid', 'VIDCredentialStudio'],
      },
    });
    console.log('Token: ', token);
  };

  return (
    <div className="blue-login">
      <header>
        <span>
          <img src={logo} alt="Logo" className="blue-login_logo" />
        </span>
        <LanguageSwitch />
      </header>
      <section className="home-container">
        <div className="info-column">
          <h1>{t('login.control')}</h1>
          <div className="vid-chain-buttons-container">
            <button
              className="new-sign-in-vid-chain"
              onClick={() => signInWithVIDchain()}
            >
              <span>{t('login.login_with')}</span>
              <span>&rarr;</span>
            </button>
          </div>
        </div>
        <div className="background-column">
          <img src={hero} alt="Background" />
        </div>
      </section>
    </div>
  );
}
