import en from './locales/en';
import es from './locales/es';
import sq from './locales/sq';
import sr from './locales/sr';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { BACKEND_URL_BASE } from '../config';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      es: { translation: es },
      sq: { translation: sq },
      sr: { translation: sr },
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'es', 'sq', 'sr'],
    interpolation: {
      escapeValue: false,
    },
    debug: BACKEND_URL_BASE.includes('localhost'),
  });

const supportedLanguages: Record<string, string> = {
  en: 'English',
  es: 'Español',
  sq: 'Shqip',
  sr: 'Srpski',
};

export { supportedLanguages };
export default i18n;
