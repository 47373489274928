import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { EmailLogin } from './pages/EmailLogin/EmailLogin';
import { Callback } from './pages/Callback/Callback';
import { LoginCallback } from './pages/LoginCallback/LoginCallback';
import { Home } from './pages/Home/Home';
import { Credentials } from './pages/Credentials/Credentials';
import { UserCredentials } from './pages/UserCredentials/UserCredentials';
import { AuditTrails } from './pages/AuditTrails/AuditTrails';
import { UserCredentialsContextProvider } from './contexts/CredentialsProviders/Providers/UserCredentialsContext';
import { AuthContext } from './contexts/AuthContext';
import { createTheme, ThemeProvider } from '@mui/material';
import { UserAuth } from './domain/userAuth';
import { AllCredentialsContextProvider } from './contexts/CredentialsProviders/Providers/AllCredentialsContext';
import { AuditTrailsContextProvider } from './contexts/CredentialsProviders/Providers/AuditTrailsContext';

function App() {
  const { accessToken } = useContext(AuthContext);
  const defaultMaterialTheme = createTheme();

  let isSuperadmin = false;
  if (accessToken) {
    const user = new UserAuth(accessToken);
    isSuperadmin = user.isSuperadmin();
  }

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <BrowserRouter>
        <Routes>
          {!accessToken && <Route path="/login" element={<Login />} />}
          <Route
            path="/"
            element={
              <AllCredentialsContextProvider>
                <Home />
              </AllCredentialsContextProvider>
            }
          >
            <Route
              path="/credentials"
              element={
                isSuperadmin ? (
                  <Navigate to={'/user-credentials'} replace />
                ) : (
                  <Credentials />
                )
              }
            />
            <Route
              path="/user-credentials"
              element={
                <UserCredentialsContextProvider>
                  <UserCredentials />
                </UserCredentialsContextProvider>
              }
            />
            <Route
              path="/audit-trails"
              element={
                <AuditTrailsContextProvider>
                  <AuditTrails />
                </AuditTrailsContextProvider>
              }
            />
            <Route
              path="/"
              element={
                <Navigate
                  to={
                    accessToken
                      ? isSuperadmin
                        ? '/user-credentials'
                        : '/credentials'
                      : '/login'
                  }
                  replace
                />
              }
            />
          </Route>
          <Route
            path="/email-login/:credentialRequestId"
            element={<EmailLogin />}
          />
          <Route path="/callback" element={<Callback />} />
          <Route path="/login-callback" element={<LoginCallback />} />
          <Route
            path="*"
            element={
              <Navigate to={accessToken ? '/credentials' : '/login'} replace />
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
