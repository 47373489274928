import React, { useContext, useState } from 'react';
import './AuditTrailsTable.css';
import { getAuditTrailsDetail } from '../../../services/vidcredentials';
import eyeSelectedIcon from '../../../assets/eyeSelectedIcon.svg';
import MaterialTable from 'material-table';
import { AuditTrailsDetailsModal } from '../AuditTrailsDetailsModal/AuditTrailsDetailsModal';
import { AuditTrailDetail, AuditTrailsList } from '../../../domain/auditTrails';
import TooltipItem from '../../../components/ToolTipItem/ToolTipItem';
import { formatDate } from '../../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { AuditTrailsContext } from '../../../contexts/CredentialsProviders/Providers/AuditTrailsContext';
import { Spinner } from '../../../components/Spinner/Spinner';
import CustomPagination from '../../../components/TablePagination/CustomPagination';
import { useTranslation } from 'react-i18next';

export function AuditTrailsTable() {
  const { t } = useTranslation();
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
  const {
    credentials: auditTrailsList,
    loadCredentials,
    tableSpecs,
    filters,
    isLoading,
  } = useContext(AuditTrailsContext);
  const [auditTrail, setAuditTrail] = useState<AuditTrailDetail>(
    {} as AuditTrailDetail,
  );
  const { entityName, ...filtersWithoutEntityName } = filters;

  const openAuditTrail = async (id: string) => {
    try {
      const auditTrailResponse = await getAuditTrailsDetail(id);
      setAuditTrail(auditTrailResponse as AuditTrailDetail);
      setDetailsModalOpen(true);
    } catch (error) {
      return;
    }
  };

  const tableColumns = [
    {
      title: t('filters.entityName'),
      field: 'entityName',
      render: (data: AuditTrailsList) => {
        return data.entityName;
      },
    },
    {
      title: t('table.agentName'),
      field: 'agent',
      render: (data: AuditTrailsList) => {
        return data.operator || t('table.notAvailable');
      },
    },
    {
      title: t('filters.actionName'),
      field: 'action',
      render: (data: AuditTrailsList) => {
        return `${t(`auditTrailsActions.${data.action}`)}`;
      },
    },
    {
      title: t('table.date'),
      field: 'date',
      render: (data: AuditTrailsList) => {
        return formatDate(data.date);
      },
    },
    {
      title: '',
      field: '',
      render: (data: AuditTrailsList) => {
        return (
          <button
            className="imageButton"
            onClick={() => openAuditTrail(data.eventId)}
          >
            <TooltipItem
              id={'detail' + data.eventId}
              title={t('table.viewDetails')}
            >
              <img
                src={eyeSelectedIcon}
                alt="Eye icon"
                height={30}
                width={30}
              />
            </TooltipItem>
          </button>
        );
      },
    },
  ];

  return (
    <div>
      <AuditTrailsDetailsModal
        auditTrail={auditTrail}
        isOpen={isDetailsModalOpen}
        setIsOpen={setDetailsModalOpen}
      />
      <MaterialTable
        key={tableSpecs.tableSize}
        columns={tableColumns}
        data={auditTrailsList}
        localization={{
          body: {
            emptyDataSourceMessage: t('table.empty'),
          },
        }}
        isLoading={isLoading}
        title={''}
        options={{
          selection: false,
          search: false,
          pageSize: tableSpecs.tableSize,
          headerStyle: {
            background: '#F9F9FB 0% 0% no-repeat padding-box',
            fontFamily: 'TT-Norms-Black',
          },
          maxBodyHeight: screen.height * 0.6,
          emptyRowsWhenPaging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ),
          Pagination: () => (
            <CustomPagination
              loadCredentials={loadCredentials}
              tableSpecs={tableSpecs}
              filters={filtersWithoutEntityName}
            />
          ),
        }}
      />
    </div>
  );
}
